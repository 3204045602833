import { callApi } from '@/api/apibase'

export const confirmPasswordReset = function(email, newPassword, token) {
    let body = {
        Email: email,
        NewPassword: newPassword,
        Token: token
    }

    return callApi('POST', '/api/user/confirmpasswordreset', body)
}