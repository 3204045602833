import { callApi } from '@/api/apibase'

export const createCustomerPrivateRegistrationRelation = function(registrationNumber, vehicleBrand, vehicleModel, relationType) {
    let body = {
        RegistrationNumber: registrationNumber,
        VehicleBrand: vehicleBrand,
        VehicleModel: vehicleModel,
        RelationType: relationType
    }

    return callApi('POST', '/api/customerregistrationrelation/private/create', body)
}