
export const getOrderPdf = function(pNumber, registrationId, orderId) {

    //Since this returns a filestream, we can't use the callApi function

    let body = {
        RegistrationId: registrationId,
        PNumber: pNumber,
        OrderId: orderId
    }
    let request = {
        method: 'POST',
        url: process.env.VUE_APP_API_URL + '/api/shop/orderpdf',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        cache: 'no-cache',
        body: JSON.stringify(body)
    }

    // If a login token is present. Put it as a header
    if (localStorage.getItem('token')) {
        // If we have an authorization token. put it in headers
        request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    
    return fetch(process.env.VUE_APP_API_URL + '/api/shop/orderpdf', request)
        .then(response => {
            return response
        })
}