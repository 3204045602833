/*************************************************
 * https://www.bezkoder.com/vue-refresh-token/
 **************************************************/

import axios from 'axios'

const setup = store => {
    axios.interceptors.response.use(function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data   
        return response
    }, function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        //DONT USE THIS INTERCEPTOR WHEN REQUESTING ANY KIND OF TOKEN
        if(error.response.config.url.includes('/Token') === false){
            //Stop the token refreshing...
            store.dispatch('LogOut')

            //Redirect to user to an error or login page
            if(error.response.status === 500){
                //There was an error
                window.location.href = '/error'
                //console.log(error)
            }
            else if(error.response.status === 401)
            {
                //console.log('error response status 401: ' )
                //Unauthorized - go to login
                window.location.href = '/error'
            }
            else
            {
                //Default
                //console.log(error)
                window.location.href = '/error'
            }
        }      

        //Clear the local storage 
        localStorage.clear()
        //Set the error to display
        if(error.response && error.response.data && error.response.data.exceptionMessage){
            // console.log('error response data exceptionMessage: ' + error.response.data.exceptionMessage)
            localStorage.setItem('error', error.response.data.exceptionMessage)
        }else if(error && error.response.data && error.response.data.error_description === 'municipality not allowed'){
            window.location.href = '/notallowed'
        } else if(error && error.response.data && error.response.data.error_description === 'no address details could be found'){
            window.location.href = '/noaddress'
        } else if(error.response.status === 401){
            localStorage.setItem('error', 'Du er ikke logget ind, eller du har ikke de nødvendige tilladelser. Log venligst ind igen.')
        }
        else {
            localStorage.setItem('error', error)
            localStorage.setItem('errorCode', error.response.status)
        }

        return Promise.reject(error)
    })
}

export default setup