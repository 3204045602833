import { callApi } from '@/api/apibase'

export const getCvrPNumberList = function(cvrNumber) {
    let body = {
        CvrNumber: cvrNumber
    }

    return callApi('POST', '/api/user/cvr/pnumberlist', body)
}


