import { callApi } from '@/api/apibase'

export const logInCriiptoPrivate = function(jwtToken) {
    let body = 'grant_type=criiptoPrivate&jwttoken='+ encodeURIComponent(jwtToken)
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*'
    }

    return callApi('POST', '/Token', null, headers, body)
}

