import { callApi } from '@/api/apibase'

export const updateCustomerRegistrationRelation = function(pNumber, customerRegistrationRelationId, paymentType, selectedCreditCardId, selectedSubscriptionId) {
    let body = {
        PNumber: pNumber,
        CustomerRegistrationRelationId: customerRegistrationRelationId,
        PaymentType: paymentType,
        SelectedCreditCardId: selectedCreditCardId,
        SelectedSubscriptionId: selectedSubscriptionId
    }

    return callApi('POST', '/api/customerregistrationrelation/update', body)
}