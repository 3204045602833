import { callApi } from '@/api/apibase'

export const logIn = function(username, password) {
    let body = 'grant_type=password&username='+ encodeURIComponent(username) +'&password=' + encodeURIComponent(password)
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*'
    }

    return callApi('POST', '/Token', null, headers, body)
}

