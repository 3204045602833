import { callApi } from '@/api/apibase'

export const refreshToken = function(refreshToken) {
    let body = 'grant_type=refresh_token&refresh_token='+refreshToken

    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*'
    }

    return callApi('POST', '/Token', null, headers, body)
}

