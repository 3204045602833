import { getSubscriptionProducts as apiGetSubscriptionProducts } from '@/api/CustomerSubscriptions/GetSubscriptionProducts'

import { createSubscriptionOrder as apiCreateSubscriptionOrder } from '@/api/CustomerSubscriptions/CreateSubscriptionOrder'
import { cancelSubscriptionOrder as apiCancelSubscriptionOrder } from '@/api/CustomerSubscriptions/CancelSubscriptionOrder'

import { getCustomerSubscriptions as apiGetCustomerSubscriptions} from '@/api/CustomerSubscriptions/GetCustomerSubscriptionList'
import{getSubscriptionOrderItemsNotActivated as apiGetSubscriptionOrderItemsNotActivated} from '@/api/CustomerSubscriptions/GetSubscriptionOrderItemsNotActivated'

export default {
    state: {
        selectedProductIdsAndAmount: [],
        activeOrderId: null
    },
    mutations: {
        setSelectedProductIdsAndAmount(state, payload) {
            state.selectedProductIdsAndAmount = payload
        },
        setActiveOrderId(state, payload) {
            state.activeOrderId = payload
        }
    },
    getters: {
        getSelectedProductIdsAndAmount: state => state.selectedProductIdsAndAmount
    },
    actions: {
        FetchSubscriptionProducts: (context, payload) => {
            return apiGetSubscriptionProducts(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        CreateSubscriptionOrder: (context, payload) => {

            //create list of productId and reg-relation id
            let list = []
            payload.productIdAndRegistrationRelationIdList.forEach(x => {
                let item = {
                    productId: x.productid,
                    customerRegistrationRelationId: x.relationid
                                     
                }

                list.push(item)
            })

            return apiCreateSubscriptionOrder(payload.pNumber, list, payload.paymentMethod)
                .then(response => {
                    if (response.status === 200) {
                        return response.data         
                    }
                    return null
                })
        },
        CancelSubscriptionOrder: (context, payload) => {
            return apiCancelSubscriptionOrder(payload.pNumber, payload.orderId)
                .then(response => {
                    if (response.status === 200) {
                        return response.data         
                    }
                    return null
                })
        },
        FetchCustomerSubscriptions: (context, payload) => {
            return apiGetCustomerSubscriptions(payload.pNumber).then(response => {
                if(response.status === 200) {
                    return response.data
                }
                return null
            })
        },
        FetchSubscriptionOrderItemsNotActivated: (context, payload) => {
            return apiGetSubscriptionOrderItemsNotActivated(payload.pNumber).then(response => {
                if(response.status === 200){
                    return response.data
                }
                return null
            })
        }
    }
}