import { callApi } from '@/api/apibase'

export const updateCustomerContactDetails = function(contactName, contactEmail, contactPhoneNumber, pNumber) {
    let body = {
        ContactName: contactName,
        ContactEmail: contactEmail,
        ContactPhoneNumber: contactPhoneNumber,
        PNumber: pNumber
    }
    return callApi('POST', '/api/user/updatecontactinfo', body)
}


