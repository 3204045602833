import { getCustomerRegistrationRowsList as apiGetCustomerRegistrationRowsList } from '@/api/CustomerRegistrationRows/GetCustomerRegistrationRowsList'

export default {
    state: {
    },
    mutations: {
    },
    getters: {
    },
    actions: {
        FetchCustomerRegistrationRowsList: (context, payload) => {
            return apiGetCustomerRegistrationRowsList(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        }
    }
}