import { callApi } from '@/api/apibase'

export const createUser = function(email, cvrNumber, phoneNumber, password) {
    let body = {
        CvrNumber: cvrNumber,
        Email: email,
        PhoneNumber: phoneNumber,
        Password: password
    }

    return callApi('POST', '/api/user/create', body)
}