import { getUserCreditCardList as apiGetUserCreditCardList } from '@/api/CustomerDetails/CustomerCreditCards/GetUserCreditCardList'
import { addCreditCard as apiAddCreditCard } from '@/api/CustomerDetails/CustomerCreditCards/AddCreditCard'
import { deleteCreditCard as apiDeleteCreditCard } from '@/api/CustomerDetails/CustomerCreditCards/DeleteCreditCard'
import { updateCreditCard as apiUpdateCreditCard } from '@/api/CustomerDetails/CustomerCreditCards/UpdateCreditCard'

export default{
    actions: {
        FetchUserCreditCardList: (context, payload) => {
            if(payload != null)
                return apiGetUserCreditCardList(payload.pNumber)
                    .then(response => {
                        if (response.status === 200) {
                            return response.data               
                        }
                        return null
                    })
            return null
        },
        FetchAddCreditCard: (context, payload) => {
            return apiAddCreditCard(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {                          
                        if(response.data.paymentWindowLink){
                            return response.data.paymentWindowLink
                        }    
                        
                        return response.data 
                    }
                    return null
                })
        },
        FetchDeleteCreditCard: (context, payload) => {
            return apiDeleteCreditCard(payload.creditCardId, payload.pNumber)
                .then(response => {
                    if (response.status === 200) {                           
                        return response.data 
                    }
                    return null
                })
        },
        FetchUpdateCreditCard: (context, payload) => {
            return apiUpdateCreditCard(payload.pNumber, payload.creditCardToReplace)
                .then(response => {
                    if (response.status === 200) {                           
                        return response.data.paymentWindowLink 
                    }
                    return null
                })
        }
    }
}