import { getCustomerRegistrationRelationsList as apiGetCustomerRegistrationRelationsList} from '@/api/CustomerRegistrationRelations/GetCustomerRegistrationRelationsList'
import { createCustomerRegistrationRelation as apiCreateCustomerRegistrationRelation } from '@/api/CustomerRegistrationRelations/CreateCustomerRegistrationRelation'
import { updateCustomerRegistrationRelation as apiUpdateCustomerRegistrationRelation } from '@/api/CustomerRegistrationRelations/UpdateCustomerRegistrationRelation'
import { deleteCustomerRegistrationRelation as apiDeleteCustomerRegistrationRelation } from '@/api/CustomerRegistrationRelations/DeleteCustomerRegistrationRelation'
import { getCustomerRegistrationDetails as apiGetCustomerRegistrationDetails } from '@/api/CustomerRegistrationRelations/GetCustomerRegistrationDetails'

import {createCustomerPrivateRegistrationRelation as apiCreateCustomerPrivateRegistrationRelation} from '@/api/CustomerRegistrationRelations/CustomerPrivate/CreateCustomerPrivateRegistrationRelation'
import {getCustomerPrivateRegistrationRelationsList as apiGetCustomerPrivateRegistrationRelationsList} from '@/api/CustomerRegistrationRelations/CustomerPrivate/GetCustomerPrivateRegistrationRelationsList'
import {deleteCustomerPrivateRegistrationRelation as apiDeleteCustomerPrivateRegistrationRelation} from '@/api/CustomerRegistrationRelations/CustomerPrivate/DeleteCustomerPrivateRegistrationRelation'

export default {
    state: {
    },
    mutations: {
    },
    getters: {
    },
    actions: {
        FetchCustomerRegistrationRelationList: (context, payload) => {
            return apiGetCustomerRegistrationRelationsList(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchCustomerRegistrationDetails: (context, payload) => {
            return apiGetCustomerRegistrationDetails(payload.pNumber, payload.registrationNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchCreateCustomerRegistrationRelation: (context, payload) => {
            return apiCreateCustomerRegistrationRelation(payload.pNumber, payload.registrationNumber, payload.paymentType, payload.selectedCreditCardId, payload.selectedSubscriptionId, payload.selectedSubscriptionOrderItemId)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchUpdateCustomerRegistrationRelation: (context, payload) => {
            return apiUpdateCustomerRegistrationRelation(payload.pNumber, payload.customerRegistrationRelationId, payload.paymentType, payload.selectedCreditCardId, payload.selectedSubscriptionId)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchDeleteCustomerRegistrationRelation: (context, payload) => {
            return apiDeleteCustomerRegistrationRelation(payload.pNumber, payload.customerRegistrationRelationId)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchCreateCustomerPrivateRegistrationRelation: (context, payload) => {
            return apiCreateCustomerPrivateRegistrationRelation(payload.registrationNumber, payload.vehicleBrand, payload.vehicleModel, payload.relationType)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        },
        FetchCustomerPrivateRegistrationRelationList: () => {
            return apiGetCustomerPrivateRegistrationRelationsList()
                .then(response => {
                    if (response.status === 200) {
                        return response.data.customerRegistrationRelationList               
                    }
                    return null
                })
        },
        FetchDeleteCustomerPrivateRegistrationRelation: (context, payload) => {
            return apiDeleteCustomerPrivateRegistrationRelation(payload.customerRegistrationRelationId)
                .then(response => {
                    if (response.status === 200) {
                        return response.data               
                    }
                    return null
                })
        }
    }
}