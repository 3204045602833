import Vue from 'vue'
import Vuex from 'vuex'
import CustomerDetails from '@/store/customerdetails'
import CustomerRegistrationRelations from '@/store/customerregistrationrelations'
import CustomerRegistrationRows from '@/store/customerregistrationrows'
import CustomerSubscriptionList from '@/store/customersubscriptions'
import CustomerCreditCards from '@/store/customercreditcards'
import SystemSettings from '@/store/systemsettings'

Vue.use(Vuex)

const getters = {}

const modules = {
    customerdetails: CustomerDetails,
    customerregistrationrelations: CustomerRegistrationRelations,
    customerregistrationrows: CustomerRegistrationRows,
    customersubscriptions: CustomerSubscriptionList,
    customercreditcards: CustomerCreditCards,
    systemsettings: SystemSettings
}

const store = new Vuex.Store({
    strict: true, // todo set to true in production

    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters,
    modules
})

if (module.hot) {
    // accept actions and mutations as hot modules
    module.hot.accept([
        '@/store/customerdetails',
        '@/store/customerregistrationrelations',
        '@/store/customerregistrationrows',
        '@/store/customersubscriptions',
        '@/store/customercreditcards',
        '@/store/systemsettings'
    ], () => {
        // require the updated modules
        // have to add .default here due to babel 6 module output
        store.hotUpdate({
            modules: {
                customerdetails: require('@/store/customerdetails').default,
                customerregistrationrelations: require('@/store/customerregistrationrelations').default,
                customerregistrationrows: require('@/store/customerregistrationrows').default,
                customersubscriptions: require('@/store/customersubscriptions').default,
                customercreditcards: require('@/store/customercreditcards').default,
                systemsettings: require('@/store/systemsettings').default
            }
        })
    })
}

export default store
